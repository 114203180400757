import React, { Component } from 'react'
import { graphql } from 'gatsby'
import he from 'he'
import Seo from '../components/seo'
import Link from '../utils/link'
import ProjectsScroll from '../components/projects-scroll'
import ProjectsGrid from '../components/projects-grid'
import { Reel, Grid } from '../components/icons'

class FilmsPage extends Component {

  state = {
    view: 'Reel',
  }

  toggleView = () => {
    let { view } = this.state
    if ( view === 'Reel' ) {
      view = 'Grid'
    } else {
      view = 'Reel'
    }
    this.setState({ view })
  }

  render() {
    const { page, posts } = this.props.data
    let { view } = this.state

    return (
      <>
        <Seo
          path={page.slug === "films" ? "/" : `/${page.slug}/`}
          title={he.decode(page.seo.title)}
          description={page.seo.metaDesc}
          keywords={page.seo.metaKeywords}
          schema={page.seo.schema.raw}
          bodyClass={page.slug}
        />
        <button
          type='button'
          className='projects__view'
          onClick={this.toggleView}
        >
          <span>{view} View</span>
          { view === 'Reel' && <Reel color={'#FFFFFF'} /> }
          { view === 'Grid' && <Grid color={'#FFFFFF'} /> }
        </button>
        { view === 'Reel' && <ProjectsScroll posts={posts.nodes} /> }
        { view === 'Grid' && <ProjectsGrid posts={posts.nodes} /> }
        <section className='footer'>
          <div className='footer__inner'>
            <ul>
              <li><Link to='/about/'>About</Link></li>
              <li><Link to='/'>Films</Link></li>
            </ul>
            <ul>
              <li><Link to='/photos/'>Photos</Link></li>
              <li><Link to='https://www.instagram.com/xmedia/'>Instagram</Link></li>
            </ul>
          </div>
        </section>
      </>
    )
  }
}

export const filmsQuery = graphql`
  query {
    posts: allWpPost(filter: {categories: {nodes: {elemMatch: {name: {eq: "Films"}}}}}) {
      nodes {
        title
        slug
        link
        acf {
          video
          tag
          gallery {
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  width: 1200
                  formats: [AUTO, WEBP, AVIF]
                  placeholder: DOMINANT_COLOR
                )
              }
            }
          }
        }
        featuredImage {
          node {
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  width: 1200
                  formats: [AUTO, WEBP, AVIF]
                  placeholder: DOMINANT_COLOR
                )
              }
            }
          }
        }
      }
    }
    page: wpPage(slug: {eq: "films"}) {
      title
      slug
      seo {
        metaDesc
        metaKeywords
        title
        schema {
          raw
        }
        opengraphImage {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`

export default FilmsPage